import { useState, useEffect, useMemo } from "react";
import isString from "lodash/isString";

interface AnyObject {
  [key: string]: any;
}

export function useTable<T extends AnyObject>(
  initialData: T[],
  countPerPage: number = 10,
  initialFilterState?: Partial<Record<string, any>>
) {
  console.log("useTable received initialData:", initialData);
  console.log("useTable received countPerPage:", countPerPage);

  // More defensive initialization
  const safeInitialData = useMemo(() => {
    if (!initialData || !Array.isArray(initialData)) {
      return [];
    }
    return [...initialData];
  }, [initialData]);

  const [data, setData] = useState<T[]>(safeInitialData);

  // More defensive data updates
  useEffect(() => {
    if (!initialData || !Array.isArray(initialData)) {
      setData([]);
      return;
    }
    setData([...initialData]);
  }, [initialData]);

  console.log("data after useState:", data);

  /*
   * Dummy loading state.
   */
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, []);

  /*
   * Handle row selection
   */
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const handleRowSelect = (recordKey: string) => {
    const selectedKeys = [...selectedRowKeys];
    if (selectedKeys.includes(recordKey)) {
      setSelectedRowKeys(selectedKeys.filter((key) => key !== recordKey));
    } else {
      setSelectedRowKeys([...selectedKeys, recordKey]);
    }
  };
  const handleSelectAll = () => {
    if (selectedRowKeys.length === data.length) {
      setSelectedRowKeys([]);
    } else {
      setSelectedRowKeys(data.map((record) => record.id));
    }
  };

  /*
   * Handle sorting
   */
  const [sortConfig, setSortConfig] = useState<AnyObject>({
    key: null,
    direction: null,
  });

  function sortData(data: T[], sortKey: string, sortDirection: string) {
    return [...data].sort((a, b) => {
      const aValue = a[sortKey];
      const bValue = b[sortKey];

      if (aValue < bValue) {
        return sortDirection === "asc" ? -1 : 1;
      } else if (aValue > bValue) {
        return sortDirection === "asc" ? 1 : -1;
      }
      return 0;
    });
  }

  const sortedData = useMemo(() => {
    let newData = data;
    if (!sortConfig.key) {
      return newData;
    }
    const sorted = sortData(newData, sortConfig.key, sortConfig.direction);
    console.log("sortedData:", sorted);
    return sorted;
  }, [sortConfig, data]);

  function handleSort(key: string) {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  }

  /*
   * Handle pagination
   */
  const [currentPage, setCurrentPage] = useState(1);
  function paginatedData(data: T[] = sortedData) {
    const start = (currentPage - 1) * countPerPage;
    const end = start + countPerPage;

    console.log(
      "Pagination - start:",
      start,
      "end:",
      end,
      "data length:",
      data.length
    );

    if (data.length > start) {
      const result = data.slice(start, end);
      console.log("Paginated result:", result);
      return result;
    }
    console.log("Returning full data");
    return data;
  }

  function handlePaginate(pageNumber: number) {
    setCurrentPage(pageNumber);
  }

  /*
   * Handle delete
   */
  function handleDelete(id: string | string[]) {
    const updatedData = Array.isArray(id)
      ? data.filter((item) => !id.includes(item.id))
      : data.filter((item) => item.id !== id);

    setData(updatedData);
  }

  /*
   * Handle Filters and searching
   */
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState<Record<string, any>>(
    initialFilterState ?? {}
  );

  function updateFilter(columnId: string, filterValue: string | any[]) {
    if (!Array.isArray(filterValue) && !isString(filterValue)) {
      throw new Error("filterValue data type should be string or array of any");
    }

    if (Array.isArray(filterValue) && filterValue.length !== 2) {
      throw new Error("filterValue data must be an array of length 2");
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      [columnId]: filterValue,
    }));
  }

  function applyFilters() {
    try {
      const searchTermLower = (searchTerm || '').toLowerCase();

      return sortedData
        .filter((item) => {
          if (!item || typeof item !== 'object') return false;
          
          const isMatchingItem = Object.entries(filters).some(
            ([columnId, filterValue]) => {
              if (!filterValue) return false;

              if (Array.isArray(filterValue)) {
                if (typeof filterValue[1] === "object") {
                  const itemValue = new Date(item[columnId]);
                  if (!itemValue || isNaN(itemValue.getTime())) return false;
                  return itemValue >= filterValue[0] && itemValue <= filterValue[1];
                }
                if (typeof filterValue[1] === "string") {
                  const itemPrice = Math.ceil(Number(item[columnId]) || 0);
                  return itemPrice >= Number(filterValue[0]) && itemPrice <= Number(filterValue[1]);
                }
              }
              
              if (isString(filterValue)) {
                const itemValue = item[columnId]?.toString().toLowerCase();
                return itemValue === filterValue.toString().toLowerCase();
              }
              
              return false;
            }
          );
          return isMatchingItem;
        })
        .filter((item) => {
          if (!item || typeof item !== 'object') return false;
          
          return Object.values(item).some((value) => {
            if (!value) return false;
            
            if (typeof value === "object") {
              return Object.values(value).some(
                (nestedItem) => nestedItem && 
                String(nestedItem).toLowerCase().includes(searchTermLower)
              );
            }
            
            return String(value).toLowerCase().includes(searchTermLower);
          });
        });
    } catch (error) {
      console.error('Error in applyFilters:', error);
      return [];
    }
  }

  /*
   * Handle searching
   */
  function handleSearch(searchValue: string) {
    setSearchTerm(searchValue);
  }

  function searchedData() {
    if (!searchTerm) return sortedData;

    const searchTermLower = searchTerm.toLowerCase();

    return sortedData.filter((item) =>
      Object.values(item).some((value) =>
        typeof value === "object"
          ? value &&
            Object.values(value).some(
              (nestedItem) =>
                nestedItem &&
                String(nestedItem).toLowerCase().includes(searchTermLower)
            )
          : value && String(value).toLowerCase().includes(searchTermLower)
      )
    );
  }

  /*
   * Reset search and filters
   */
  function handleReset() {
    setData(() => initialData);
    handleSearch("");
    if (initialFilterState) return setFilters(initialFilterState);
  }

  /*
   * Set isFiltered and final filtered data
   */
  const isFiltered = applyFilters().length > 0;
  function calculateTotalItems() {
    if (isFiltered) {
      return applyFilters().length;
    }
    if (searchTerm) {
      return searchedData().length;
    }
    return sortedData.length;
  }
  const filteredAndSearchedData = useMemo(() => {
    const filtered = isFiltered ? applyFilters() : searchedData();
    console.log("filteredAndSearchedData:", filtered);
    return filtered;
  }, [isFiltered, searchTerm, sortedData, filters]);

  const tableData = useMemo(() => {
    const paginated = paginatedData(filteredAndSearchedData);
    console.log("Final tableData:", paginated);
    return paginated;
  }, [filteredAndSearchedData, currentPage, countPerPage]);

  console.log("Final tableData in useTable:", tableData);

  /*
   * Go to first page when data is filtered and searched
   */
  useEffect(() => {
    handlePaginate(1);
  }, [isFiltered, searchTerm]);

  // useTable returns
  return {
    isLoading,
    isFiltered,
    tableData,
    // pagination
    currentPage,
    handlePaginate,
    totalItems: calculateTotalItems(),
    // sorting
    sortConfig,
    handleSort,
    // row selection
    selectedRowKeys,
    setSelectedRowKeys,
    handleRowSelect,
    handleSelectAll,
    // searching
    searchTerm,
    handleSearch,
    // filters
    filters,
    updateFilter,
    applyFilters,
    handleDelete,
    handleReset,
  };
}
